import { getContracts } from "@bkry/bowline-redux/contracts";
import { getProjectRelationships } from "@bkry/bowline-redux/projects";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { MdCreate, MdInput } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  PopoverBody,
  PopoverHeader,
  Row,
  UncontrolledPopover,
} from "reactstrap";

/**
 * ContractAddModal
 */
const ContractAddModal = (props, context) => {
  const { projectId } = useParams();
  const { trigger } = props;

  const projectRelationships = useSelector((state) =>
    getProjectRelationships(state, projectId)
  );

  const contracts = useSelector((state) =>
    getContracts(
      state,
      projectRelationships?.contracts?.data?.map((contract) => contract.id)
    )
  );

  const [selectedContract, setSelectedContract] = useState(
    contracts?.[0]?.address ? contracts[0].address : null
  );

  useEffect(() => {
    if (!selectedContract) {
      setSelectedContract(
        contracts?.[0]?.address ? contracts[0].address : null
      );
    }
  }, [contracts]);

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      {!trigger ? (
        <Button color="primary" size="lg" onClick={() => setIsOpen(true)}>
          {context.t("Add Contract")}
        </Button>
      ) : (
        <>{React.cloneElement(trigger, { onClick: () => setIsOpen(true) })}</>
      )}
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>{context.t("Add Contract")}</ModalHeader>
        <ModalBody>
          <Card className="my-3">
            <CardBody>
              <Row>
                <Col xs={3}>
                  <MdInput className="w-100 h-100" />
                </Col>
                <Col xs={9}>
                  <p className="h6 fw-bold">
                    <Link
                      to={`/projects/${projectId}/contracts/import`}
                      className="stretched-link text-reset text-decoration-none"
                    >
                      {context.t("Import Contract")}
                    </Link>
                  </p>
                  <p>
                    {context.t(
                      "Select one of your already deployed contracts to import on bowline. This process might take some time, but on the meantime you can continue using the app. The supported blockchains are Ethereum, Goerli, Sepolia, Polygon, Mumbai and Amoy."
                    )}
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className="my-3 cursor-pointer" id="contractcreatemodal">
            <CardBody>
              <Row>
                <Col xs={3}>
                  <MdCreate className="w-100 h-100" />
                </Col>
                <Col xs={9}>
                  <p className="h6 fw-bold">
                    <Link
                      to={`/projects/${projectId}/contracts/new`}
                      className="stretched-link text-reset text-decoration-none"
                    >
                      {context.t("Create New Contract")}
                    </Link>
                  </p>
                  <p>
                    {context.t(
                      "Create a new ERC721 or ERC1155 Contract. Since we are still in beta, our team will guide you through this process and help you to identify your needs. Bowline offers more than 20 built in contract modules that enable you to create a feature-rich smart contract."
                    )}
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <UncontrolledPopover
            target="contractcreatemodal"
            trigger="legacy"
            placement="top"
          >
            <PopoverHeader>{context.t("Disabled")}</PopoverHeader>
            <PopoverBody>
              <p className="text-dark">
                {context.t(
                  "This is an invite only/ beta only feature, please contact us for more information"
                )}
              </p>
              <a
                className="btn btn-primary me-3 mb-2"
                target="_blank"
                rel="noopener noreferrer"
                href="https://forms.gle/qKDo1mpovSyggUJT9"
              >
                {context.t("Contact Us")}
              </a>
            </PopoverBody>
          </UncontrolledPopover>
        </ModalBody>
      </Modal>
    </>
  );
};

/**  define proptypes for ContractAddModal  */
ContractAddModal.propTypes = {
  trigger: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

/**  define defaultProps for ContractAddModal  */
ContractAddModal.defaulProps = {};

/**  define proptype for the 'translation' function  */
ContractAddModal.contextTypes = {
  t: PropTypes.func,
};

/** export the component as redux connected component */
export default ContractAddModal;
